export const colors = [
  "#4b94bf",
  "#f39c12",
  "#00c0ef",
  "#00a65a",
  "#dd4b39",
  "#828282",
  "#cda819",
  "#9966ff"
];

export const whiteColor = "#fff";
export const lightColor = "#b8c7ce";
export const semilightColor = "#728188";
export const mediumColor = "#2c3b41";
export const darkColor = "#222d32";
export const darkerColor = "#1e282c";
export const allBlackColor = "#171b1d";

export const whiteColor50 = "#fff7f";
export const lightColor50 = "#b8c7ce7f";
export const semilightColor50 = "#7281887f";
export const mediumColor50 = "#2c3b417f";
export const darkColor50 = "#222d327f";
export const darkerColor50 = "#1e282c7f";
export const allBlackColor50 = "#171b1d7f";

window.echarts.registerTheme("skin-dark", {
  color: colors,
  textStyle: {},
  title: {
    textStyle: {
      color: lightColor
    },
    subtextStyle: {
      color: semilightColor
    }
  },
  line: {
    itemStyle: {
      normal: {
        borderWidth: 1
      }
    },
    lineStyle: {
      normal: {
        width: 2
      }
    },
    symbolSize: 4,
    symbol: "emptyCircle",
    smooth: false
  },
  radar: {
    itemStyle: {
      normal: {
        borderWidth: 1
      }
    },
    lineStyle: {
      normal: {
        width: 2
      }
    },
    symbolSize: 4,
    symbol: "emptyCircle",
    smooth: false
  },
  bar: {
    itemStyle: {
      normal: {
        barBorderWidth: 0,
        barBorderColor: mediumColor
      },
      emphasis: {
        barBorderWidth: 0,
        barBorderColor: mediumColor
      }
    }
  },
  pie: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  scatter: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  boxplot: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  parallel: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  sankey: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  funnel: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    }
  },
  gauge: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      },
      emphasis: {
        borderWidth: 0,
        borderColor: whiteColor
      }
    }
  },
  candlestick: {
    itemStyle: {
      normal: {
        color: lightColor,
        color0: mediumColor,
        borderColor: semilightColor,
        borderColor0: darkColor,
        borderWidth: 1
      }
    }
  },
  graph: {
    itemStyle: {
      normal: {
        borderWidth: 0,
        borderColor: mediumColor
      }
    },
    lineStyle: {
      normal: {
        width: 1,
        color: semilightColor
      }
    },
    symbolSize: 4,
    symbol: "emptyCircle",
    smooth: false,
    color: colors,
    label: {
      normal: {
        textStyle: {
          color: lightColor
        }
      }
    }
  },
  map: {
    itemStyle: {
      normal: {
        areaColor: mediumColor,
        borderColor: semilightColor,
        borderWidth: 0.5
      },
      emphasis: {
        areaColor: semilightColor,
        borderColor: lightColor,
        borderWidth: 1
      }
    },
    label: {
      normal: {
        textStyle: {
          color: lightColor
        }
      },
      emphasis: {
        textStyle: {
          color: lightColor
        }
      }
    }
  },
  geo: {
    itemStyle: {
      normal: {
        areaColor: mediumColor,
        borderColor: semilightColor,
        borderWidth: 0.5
      },
      emphasis: {
        areaColor: semilightColor,
        borderColor: lightColor,
        borderWidth: 1
      }
    },
    label: {
      normal: {
        textStyle: {
          color: lightColor
        }
      },
      emphasis: {
        textStyle: {
          color: whiteColor
        }
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: mediumColor
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: semilightColor
      }
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: [mediumColor]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [semilightColor50, lightColor50]
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: semilightColor
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [mediumColor]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [semilightColor50, lightColor50]
      }
    }
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: semilightColor
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [mediumColor]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [semilightColor50, lightColor50]
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: semilightColor
      }
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: semilightColor
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [mediumColor]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [semilightColor50, lightColor50]
      }
    }
  },
  toolbox: {
    iconStyle: {
      normal: {
        borderColor: darkerColor
      },
      emphasis: {
        borderColor: mediumColor
      }
    }
  },
  legend: {
    inactiveColor: mediumColor,
    textStyle: {
      color: lightColor
    }
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: semilightColor,
        width: 1
      },
      crossStyle: {
        color: semilightColor,
        width: 1
      }
    }
  },
  timeline: {
    lineStyle: {
      color: mediumColor,
      width: 1
    },
    itemStyle: {
      normal: {
        color: lightColor,
        borderWidth: 1
      },
      emphasis: {
        color: whiteColor
      }
    },
    controlStyle: {
      normal: {
        color: mediumColor,
        borderColor: semilightColor,
        borderWidth: 0.5
      },
      emphasis: {
        color: semilightColor,
        borderColor: mediumColor,
        borderWidth: 0.5
      }
    },
    checkpointStyle: {
      color: semilightColor,
      borderColor: lightColor50
    },
    label: {
      normal: {
        textStyle: {
          color: lightColor
        }
      },
      emphasis: {
        textStyle: {
          color: whiteColor
        }
      }
    }
  },
  visualMap: {
    color: [lightColor, semilightColor]
  },
  dataZoom: {
    dataBackgroundColor: semilightColor50,
    fillerColor: lightColor50,
    handleColor: whiteColor,
    handleSize: "100%",
    textStyle: {
      color: lightColor
    }
  },
  markPoint: {
    label: {
      normal: {
        textStyle: {
          color: lightColor
        }
      },
      emphasis: {
        textStyle: {
          color: whiteColor
        }
      }
    }
  }
});
